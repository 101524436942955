import { Block } from "baseui/block";
import React, { useRef } from "react";
import { useRouter } from "next/router";
import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { PhoneInput, COUNTRIES } from "baseui/phone-input";
import { RecaptchaVerifier } from "firebase/auth";
import { auth as Authentication } from "../firebase/config";
import { useAuth } from "../context/useAuth";
import { useProduct } from "../context/useProduct";

import { getCurrentUser } from "../services/authService";

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  try {
    const user = await getCurrentUser(ctx);
    const { query } = ctx;
    const { subscription, stripeAccount } = query;

    if (user.protected === false) {
      return {
        props: {},
      };
    }
    if (user.uid) {
      if (subscription && stripeAccount) {
        return {
          redirect: {
            destination: `/shop/dashboard?subscription=${subscription}&stripeAccount=${stripeAccount}`,
            permanent: false,
          },
        };
      } else {
        return {
          redirect: {
            destination: "/shop/dashboard",
            permanent: false,
          },
        };
      }
    }

    return {
      props: {},
    };
  } catch (error) {
    console.log(error);
  }
};

const Index: React.FC = () => {
  const [css, theme] = useStyletron();
  const inputReference = useRef(null);
  const router = useRouter();

  const { phoneSingUp } = useAuth();
  const [country, setCountry] = React.useState(COUNTRIES.US);
  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const dialCode = country.dialCode;

  const generateRecaptcha = () => {
    setLoading(true);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": () => {},
      },
      Authentication
    );
  };

  const updateText = (e) => {
    if (!isNaN(e)) {
      if (e.length > 10) {
        return;
      }
      setText(e);
    } else {
      setText(e);
    }
  };

  React.useEffect(() => {
    if (text && text.length >= 2 && !isNaN(text[0]) && !isNaN(text[1])) {
      inputReference.current.focus();
    }
  }, [text]);

  const handlePhoneEmailSignUp = () => {
    setLoading(true);
    if (text.includes("@")) {
      // api call to sendOtpToEmail
      fetch("/api/sendOtpToEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: text }),
      })
        .then((res) => res.json())
        .then((data) => {
          // store email in the local storage
          localStorage.setItem("email", text);
          sessionStorage.setItem("otp", data.otp);

          let query;
          if (router?.query?.subscription && router?.query?.stripeAccount) {
            query = {
              subscription: router?.query?.subscription,
              stripeAccount: router?.query?.stripeAccount,
            };
          } else {
            query = {};
          }

          router.push({
            pathname: "/shop/verify_email",
            query: query,
          });
        });
    }
    if (text.length == 10) {
      generateRecaptcha();

      phoneSingUp(`${dialCode}${text}`, {
        subscription: router?.query?.subscription,
        stripeAccount: router?.query?.stripeAccount,
      });
      setLoading(true);
    }
  };

  return (
    <Block
      className={css({
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#000000",
      })}
    >
      <Block
        as={"div"}
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"64px"}
        justifyContent={"center"}
        backgroundColor={"#1b1d1f"}
      >
        <Block
          as={"div"}
          color={"white"}
          marginLeft={"16px"}
          overrides={{
            Block: {
              style: {
                fontSize: "24px",
                fontWeight: "bold",
              },
            },
          }}
        >
          monthlysubs.co
        </Block>
      </Block>
      <Block
        className="responsiveBlock-login"
        as={"div"}
        padding={" 16px"}
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
      >
        <FormControl
          label={() => "What’s your phone number or email?"}
          ref={inputReference}
          overrides={{
            Label: {
              style: {
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "8px",
              },
            },
          }}
        >
          {text && text.length >= 2 && !isNaN(text[0]) && !isNaN(text[1]) ? (
            <PhoneInput
              country={country}
              onCountryChange={({ option }) => setCountry(option)}
              text={text}
              inputMode="text"
              onTextChange={(e) => updateText(e.currentTarget.value)}
              overrides={{
                Input: {
                  props: {
                    inputRef: inputReference,
                  },
                },
              }}
            />
          ) : (
            <Input
              onChange={(e) => updateText(e.currentTarget.value)}
              text={text}
              placeholder="Phone number or email"
            />
          )}
        </FormControl>

        <Button
          type="button"
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: "100%",
              }),
            },
          }}
          onClick={handlePhoneEmailSignUp}
          isLoading={loading}
          disabled={loading || !text}
        >
          Continue
        </Button>
        <div id="recaptcha-container"></div>
        <Block>
          <Block
            as="p"
            color={"white"}
            overrides={{
              Block: {
                style: {
                  fontSize: "12px",
                  marginTop: "24px",
                  fontWeight: "300",
                  maxWidth: "300px",
                },
              },
            }}
          >
            By proceeding, you consent to get calls or SMS messages, including
            by automated dialer, from monthlysubs and its affiliates to the
            number provided.
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default Index;
